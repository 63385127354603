import { Card } from '@mui/material';
import { Box, styled } from '@mui/system';

const CardRoot = styled(Card)(() => ({
  height: '100%',
  padding: '20px 24px',
}));

interface CardTitleProps {
  subtitle?: string;
}

const CardTitle = styled('div')<CardTitleProps>(({ subtitle }) => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize',
  marginBottom: !subtitle ? '16px' : 'auto',
}));

interface Props {
  children?: React.ReactNode;
  title?: string;
  subtitle?: string;
  icon?: string;
}

const SimpleCard: React.FC<Props> = ({ children, title, subtitle, icon }) => {
  return (
    <CardRoot elevation={6}>
      <CardTitle subtitle={subtitle}>{title}</CardTitle>
      {subtitle && <Box sx={{ mb: 2 }}>{subtitle}</Box>}
      {children}
    </CardRoot>
  );
};

export default SimpleCard;
