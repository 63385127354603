import { styled } from '@mui/material';

const Content = styled('div')(() => ({
  flexGrow: 1,
  height: '100%',
  position: 'relative',
}));

interface Props {
  children?: React.ReactNode;
}

const SidenavContent: React.FC<Props> = ({ children }) => {
  return <Content>{children}</Content>;
};

export default SidenavContent;
