import { Container } from 'app/components/Typography';
import { Vehicle } from 'app/types/rules/user.type';
import { BoxContainer } from '../SmallComponents';
import UpdateVehicleForm from './UpdateVehicleForm';

interface Props {
  vehicles: Vehicle[];
  updateVehicle: (vehicle: Vehicle) => Promise<void>;
}

const VehicleForm: React.FC<Props> = ({ vehicles, updateVehicle }) => {
  return (
    <Container>
      <h1>Vehicles</h1>
      <BoxContainer>
        {vehicles.map((vehicle) => (
          <UpdateVehicleForm vehicle={vehicle} updateVehicle={updateVehicle} />
        ))}
      </BoxContainer>
    </Container>
  );
};

export default VehicleForm;
