import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/styled-engine';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import ReactDOM from 'react-dom';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import App from './app/App';

ReactDOM.render(
  <HashRouter>
    <StyledEngineProvider injectFirst>
      {/* <BrowserRouter basename="/"> */}
      <CssBaseline />
      <App />
      {/* </BrowserRouter> */}
    </StyledEngineProvider>
  </HashRouter>,
  document.getElementById('root')
);
