import Loadable from 'app/components/Loadable';
import { AuthRoute } from 'app/routes';
import { RoleEnum } from 'app/types/enum/user.enum';
import { lazy } from 'react';

const Analytics = Loadable(lazy(() => import('./Analytics')));

const dashboardRoutes: AuthRoute[] = [
  {
    path: '/dashboard/default',
    element: <Analytics />,
    auth: [RoleEnum.ADMIN],
  },
];

export default dashboardRoutes;
