import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { AxiosHttpError } from '../types/error/error.types';
import { refreshToken, setToken } from './utils/token.utils';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
setToken();
export default axios;

// request without retry if 401
export async function sendRawRequest<T, R>(
  method: 'get' | 'post' | 'put' | 'patch' | 'delete',
  url: string,
  data?: T,
  config?: AxiosRequestConfig<T>
) {
  if (method === 'get') return axios.get<T, AxiosResponse<R>>(url, config);
  return axios[method]<T, AxiosResponse<R>>(url, data, config);
}

// request with retry if 401
export async function sendRequest<T, R>(
  method: 'get' | 'post' | 'put' | 'patch' | 'delete',
  url: string,
  data?: T,
  config?: AxiosRequestConfig<T>
) {
  try {
    return await sendRawRequest<T, R>(method, url, data, config);
  } catch (error) {
    const axiosError = error as Error | AxiosHttpError;
    if (axios.isAxiosError(axiosError) && axiosError.response?.status === 403) {
      await refreshToken();
      return sendRawRequest<T, R>(method, url, data, config);
    }
    throw error;
  }
}
