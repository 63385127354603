import { ApolloError } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Checkbox, Divider, Modal, Radio, Switch, TextField, useMediaQuery } from '@mui/material';
import { styled, SxProps, Theme } from '@mui/system';
import { Container, Span } from 'app/components/Typography';
import { screenSizes } from 'app/utils/constant';
import { Formik } from 'formik';
import * as Yup from 'yup';
import SendIcon from '@mui/icons-material/Send';
import { UserInput } from 'app/types/rules/user.type';
import { useState } from 'react';
import { GenderEnum } from 'app/types/enum/user.enum';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    )
    .required('Password is required!'),
  email: Yup.string().email('Invalid Email address').required('Email is required!'),
  username: Yup.string().required('Username is required!'),
  phone: Yup.string()
    .matches(/^\+?([0-9]{8,20})$/, 'Invalid Phone format')
    .nullable(),
});

const textFieldStyle: SxProps<Theme> = {
  margin: '10px 20px',
};

const submitButtonStyle: SxProps<Theme> = {
  width: '115px',
  margin: '10px 20px',
};

const ModalButtonStyle: SxProps<Theme> = {
  width: '30%',
  margin: '10px 20px',
};

const FormContainer = styled('form')(({ theme }) => ({
  background: 'white',
  borderRadius: '8px',
  padding: '8px 24px 16px',
  boxShadow:
    'rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 4%) 0px 3px 4px 0px, rgb(0 0 0 / 4%) 0px 1px 8px 0px !important',

  [theme.breakpoints.down('md')]: {
    padding: '0',
  },
}));

const ModalContainer = styled('div')(() => ({
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const ModalContent = styled('div')(({ theme }) => ({
  background: 'white',
  width: '30%',
  padding: '2rem',
  borderRadius: '7px',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    width: '80%',
  },
}));

interface Props {
  initialValues: UserInput;
  loading: boolean;
  error?: string;
  editMode?: boolean;
  cvPath?: string;
  handleSubmit: (values: UserInput) => Promise<void>;
}

const UserForm: React.FC<Props> = ({
  initialValues,
  loading,
  error,
  editMode = false,
  cvPath,
  handleSubmit,
}) => {
  const isMedium = useMediaQuery(`(max-width:${screenSizes.tablet})`);
  const [modal, setModal] = useState(false);
  const [password, setPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();

  const fullPath = (path: string) => {
    return process.env.REACT_APP_API_URL + path;
  };

  return (
    <Container>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <FormContainer onSubmit={handleSubmit}>
            {cvPath && (
              <a
                href={fullPath(cvPath)!}
                download
                style={{
                  textDecoration: 'underline',
                  color: 'blue',
                  cursor: 'pointer',
                }}
              >
                Download CV
              </a>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: isMedium ? 'wrap' : 'nowrap',
                margin: '10px 0',
                flexDirection: isMedium ? 'column' : 'row',
              }}
            >
              <div style={{ flex: isMedium ? 1 : 0.5 }}>
                <TextField
                  name="username"
                  type="text"
                  label="Username"
                  size="small"
                  sx={{ ...textFieldStyle, width: '90%' }}
                  variant="outlined"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.username && errors.username}
                  error={Boolean(errors.username && touched.username)}
                />

                <TextField
                  name="email"
                  type="email"
                  label="Email Address"
                  size="small"
                  sx={{ ...textFieldStyle, width: '90%' }}
                  variant="outlined"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.email && errors.email}
                  error={Boolean(errors.email && touched.email)}
                />

                <TextField
                  name="password"
                  type="password"
                  label="Password"
                  size="small"
                  sx={{ ...textFieldStyle, width: '90%' }}
                  variant="outlined"
                  value={newPassword ?? values.password}
                  onChange={(e) => {
                    if (!editMode) handleChange(e);
                  }}
                  onBlur={(e) => {
                    if (newPassword) setFieldValue('password', newPassword);
                    else handleBlur(e);
                  }}
                  onClick={() => editMode && setModal(true)}
                  helperText={touched.password && errors.password}
                  error={Boolean(errors.password && touched.password)}
                />

                <TextField
                  name="phone"
                  type="text"
                  label="Phone Number"
                  size="small"
                  sx={{ ...textFieldStyle, width: '90%' }}
                  variant="outlined"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.phone && errors.phone}
                  error={Boolean(errors.phone && touched.phone)}
                />

                <TextField
                  multiline
                  rows={4}
                  name="address"
                  type="text"
                  label="Address"
                  size="small"
                  sx={{ ...textFieldStyle, width: '90%' }}
                  variant="outlined"
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.address && errors.address}
                  error={Boolean(errors.address && touched.address)}
                />
                <TextField
                  multiline
                  rows={4}
                  name="bio"
                  type="text"
                  label="Bio"
                  size="small"
                  sx={{ ...textFieldStyle, width: '90%' }}
                  variant="outlined"
                  value={values.bio}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.bio && errors.bio}
                  error={Boolean(errors.bio && touched.bio)}
                />
              </div>

              <div style={{ flex: isMedium ? 1 : 0.5 }}>
                <TextField
                  name="school"
                  type="text"
                  label="School"
                  size="small"
                  sx={{ ...textFieldStyle, width: '90%' }}
                  variant="outlined"
                  value={values.school}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.school && errors.school}
                  error={Boolean(errors.school && touched.school)}
                />

                <TextField
                  name="degree"
                  type="text"
                  label="Degree"
                  size="small"
                  sx={{ ...textFieldStyle, width: '90%' }}
                  variant="outlined"
                  value={values.degree}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.degree && errors.degree}
                  error={Boolean(errors.degree && touched.degree)}
                />

                <TextField
                  name="studyField"
                  type="text"
                  label="Study Field"
                  size="small"
                  sx={{ ...textFieldStyle, width: '90%' }}
                  variant="outlined"
                  value={values.studyField}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.studyField && errors.studyField}
                  error={Boolean(errors.studyField && touched.studyField)}
                />

                <TextField
                  name="grade"
                  type="text"
                  label="Grade"
                  size="small"
                  sx={{ ...textFieldStyle, width: '90%' }}
                  variant="outlined"
                  value={values.grade}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.grade && errors.grade}
                  error={Boolean(errors.grade && touched.grade)}
                />

                <TextField
                  name="website"
                  type="text"
                  label="Website"
                  size="small"
                  sx={{ ...textFieldStyle, width: '90%' }}
                  variant="outlined"
                  value={values.website}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.website && errors.website}
                  error={Boolean(errors.website && touched.website)}
                />

                <TextField
                  label="Date Of Birth"
                  sx={{ ...textFieldStyle, width: '90%' }}
                  name="dateOfBirth"
                  type="date"
                  value={
                    values.dateOfBirth
                      ? new Date(values.dateOfBirth).toISOString().split('T')[0]
                      : undefined
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.dateOfBirth && Boolean(errors.dateOfBirth)}
                  helperText={touched.dateOfBirth && errors.dateOfBirth}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />

                <div style={{ width: '90%', margin: 'auto' }}>
                  <h4>Gender</h4>
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '5rem' }}>
                      <label style={{ marginRight: '0.5rem' }}>Male</label>

                      <Radio
                        size="small"
                        checked={values.gender === GenderEnum.Male}
                        onChange={(e) => {
                          if (e.target.checked) setFieldValue('gender', GenderEnum.Male);
                          else setFieldValue('gender', GenderEnum.Female);
                        }}
                        sx={{ padding: 0 }}
                      />
                    </div>
                    <div>
                      <label style={{ marginRight: '0.5rem' }}>Female</label>

                      <Radio
                        size="small"
                        checked={values.gender === GenderEnum.Female}
                        onChange={(e) => {
                          if (e.target.checked) setFieldValue('gender', GenderEnum.Female);
                          else setFieldValue('gender', GenderEnum.Male);
                        }}
                        sx={{ padding: 0 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Divider />

            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2 style={{ marginRight: '2rem' }}>Premium</h2>
                {/* <Switch
                  size="small"
                  checked={isChecked}
                  onChange={(e) => {
                    if (e.target.checked) setIsChecked(true);
                    else setIsChecked(false);
                  }}
                  sx={{ padding: 0 }}
                /> */}
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: isMedium ? 'wrap' : 'nowrap',
                  margin: '10px 0',
                  flexDirection: isMedium ? 'column' : 'row',
                }}
              >
                <TextField
                  label="Premium Start Date"
                  sx={{ ...textFieldStyle, width: '90%' }}
                  name="premiumStart"
                  type="date"
                  value={
                    values.premiumStart
                      ? new Date(values.premiumStart).toISOString().split('T')[0]
                      : undefined
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.premiumStart && Boolean(errors.premiumStart)}
                  helperText={touched.premiumStart && errors.premiumStart}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />

                <TextField
                  label="Premium End Date"
                  sx={{ ...textFieldStyle, width: '90%' }}
                  name="premiumEnd"
                  type="date"
                  value={
                    values.premiumEnd
                      ? new Date(values.premiumEnd).toISOString().split('T')[0]
                      : undefined
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.premiumEnd && Boolean(errors.premiumEnd)}
                  helperText={touched.premiumEnd && errors.premiumEnd}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </div>
            </div>

            <div>
              <LoadingButton
                loading={loading}
                sx={submitButtonStyle}
                loadingPosition="start"
                startIcon={<SendIcon />}
                variant="contained"
                type="submit"
              >
                Submit
              </LoadingButton>
            </div>
            <Span color="red" sx={{ marginLeft: '1rem' }}>
              {error}
            </Span>
          </FormContainer>
        )}
      </Formik>

      <Modal
        open={modal}
        onClose={() => {
          setModal(false);
        }}
      >
        <ModalContainer>
          <ModalContent>
            <h4> Change password </h4>

            <TextField
              name="password"
              type="Password"
              label="new password"
              size="small"
              sx={{ ...textFieldStyle, width: '70%' }}
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div>
              <LoadingButton
                loading={loading}
                sx={ModalButtonStyle}
                variant="outlined"
                type="submit"
                onClick={() => {
                  setNewPassword(password);
                  setModal(false);
                }}
                color={'error'}
              >
                Change
              </LoadingButton>

              <LoadingButton
                sx={ModalButtonStyle}
                variant="outlined"
                type="button"
                onClick={() => {
                  setModal(false);
                }}
                color={'secondary'}
              >
                Cancel
              </LoadingButton>
            </div>
          </ModalContent>
        </ModalContainer>
      </Modal>
    </Container>
  );
};

export default UserForm;
