import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import RootReducer from './reducers/RootReducer';

// initial state defined with redux store as default value
const initialState = {};

export const Store = configureStore({
  reducer: RootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([logger]),
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: initialState,
});

export type Dispatch = typeof Store.dispatch;
