import { LayoutBarSettings } from 'app/types/rules/styles.type';

const Layout1Settings: LayoutBarSettings = {
  leftSidebar: {
    show: true,
    mode: 'full', // full, close, compact, mobile,
    theme: 'slateDark1',
    // bgimgurl: '/assets/images/sidebar/sidebar-bg-dark.jpg',
  },
  topbar: {
    show: true,
    fixed: true,
    theme: 'whiteBlue',
  },
};

export default Layout1Settings;
