import { Suspense } from 'react';
import Loading from './Loading';

const Loadable =
  <T extends object>(Component: React.LazyExoticComponent<() => JSX.Element>) =>
  (props: T) => {
    return (
      <Suspense fallback={<Loading />}>
        <Component {...props} />
      </Suspense>
    );
  };

export default Loadable;
