import { styled } from '@mui/material';

const Container = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  position: 'relative',
}));

interface Props {
  children?: React.ReactNode;
}

const SidenavContainer: React.FC<Props> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default SidenavContainer;
