import axios from 'axios';
import { getAccessToken } from '../rest/users';

export function setToken() {
  const accessToken: string | null =
    typeof window !== 'undefined' ? localStorage.getItem('AccessToken') : null;
  if (accessToken) axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}

export function storeAuthResult(accessToken: string | undefined, refreshToken: string | undefined) {
  if (accessToken) localStorage.setItem('AccessToken', accessToken);
  if (refreshToken) localStorage.setItem('RefreshToken', refreshToken);
  setToken();
}

export function removeAuth() {
  localStorage.removeItem('RefreshToken');
  localStorage.removeItem('AccessToken');
  setToken();
}

export async function refreshToken() {
  const RefreshToken: string | null = localStorage.getItem('RefreshToken');
  if (!RefreshToken) return false;
  try {
    const res = await getAccessToken(RefreshToken);
    const AccessToken = res.data.accessToken;
    localStorage.setItem('AccessToken', AccessToken);
    setToken();
  } catch (error) {
    removeAuth();
    return null;
  }
}
