import { styled } from '@mui/system';

export const FormTitle = styled('h3')(({ theme }) => ({
  fontWeight: '500',
  lineHeight: '1.5',
  color: theme.palette.text.primary,
  padding: '1rem',
  margin: '0',
}));

export const FormContainer = styled('form')(() => ({
  background: 'white',
  borderRadius: '8px',
  boxShadow:
    'rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 4%) 0px 3px 4px 0px, rgb(0 0 0 / 4%) 0px 1px 8px 0px !important',
}));

export const BoxContainer = styled('div')(() => ({
  background: 'white',
  borderRadius: '8px',
  boxShadow:
    'rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 4%) 0px 3px 4px 0px, rgb(0 0 0 / 4%) 0px 1px 8px 0px !important',
}));

export const ModalFormContainer = styled('form')(({ theme }) => ({
  background: 'white',
  borderRadius: '8px',
  boxShadow:
    'rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 4%) 0px 3px 4px 0px, rgb(0 0 0 / 4%) 0px 1px 8px 0px !important',
  padding: '1rem',
  width: '40%',
  margin: '10% auto',
  [theme.breakpoints.down('md')]: {
    width: '90%',
  },
}));
