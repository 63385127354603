import Loadable from 'app/components/Loadable';
import { AuthRoute } from 'app/routes';
import { RoleEnum } from 'app/types/enum/user.enum';
import { lazy } from 'react';
import CreateUser from './CreateUser';
import UpdateUser from './UpdateUser';
import ViewUser from './ViewUser';

const UsersList = Loadable(lazy(() => import('./UsersList')));

const userRoutes: AuthRoute[] = [
  { path: '/users/list', element: <UsersList />, auth: [RoleEnum.ADMIN] },
  { path: '/users/create', element: <CreateUser />, auth: [RoleEnum.ADMIN] },
  { path: '/users/update/:id', element: <UpdateUser />, auth: [RoleEnum.ADMIN] },
  { path: '/users/view/:id', element: <ViewUser />, auth: [RoleEnum.ADMIN] },
];

export default userRoutes;
