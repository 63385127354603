import { FetchResult, useLazyQuery, useMutation } from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import { getUser, loginApi } from 'app/api/rest/users';

import { removeAuth, storeAuthResult } from 'app/api/utils/token.utils';
import { Loading } from 'app/components';
import { LoginResponse } from 'app/types/api/user.type';
import { User } from 'app/types/rules/user.type';
import { createContext, FC, useCallback, useEffect, useReducer, useState } from 'react';

type VerificationStatus = 'VERIFIED' | 'UNVERIFIED';

interface ContextData {
  user?: User;
  isAuthenticated: boolean;
  verified?: VerificationStatus;
  loading: boolean;
  error?: string;
  login: (email: string, password: string) => Promise<LoginResponse | undefined>;
  logout: () => void;
  verifyEmail: (token: string) => Promise<FetchResult<any>>;
  resendVerificationEmail: (email: string) => Promise<FetchResult<any>>;
  forgetPassword: (email: string) => Promise<FetchResult<any>>;
  resetPassword: (token: string, newPassword: string) => Promise<FetchResult<any>>;
  updateUserEmail: (email: string) => Promise<FetchResult<any>>;
  removeError: () => void;
}

const initialState: ContextData = {
  isAuthenticated: false,
  loading: false,
  login: () => Promise.resolve(undefined),
  logout: () => {},
  verifyEmail: () => Promise.resolve({}),
  resendVerificationEmail: () => Promise.resolve({}),
  forgetPassword: () => Promise.resolve({}),
  resetPassword: () => Promise.resolve({}),
  updateUserEmail: () => Promise.resolve({}),
  removeError: () => {},
};

const AuthContext = createContext(initialState);

type ActionType =
  | { type: 'LOADING'; payload: boolean }
  | { type: 'VERIFICATION'; payload: VerificationStatus }
  | { type: 'GET_USER'; payload: User }
  | { type: 'LOGOUT' }
  | { type: 'ERROR'; payload?: string };

const reducer = (state: ContextData, action: ActionType): ContextData => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, loading: action.payload };
    case 'GET_USER':
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: undefined,
      };
    case 'ERROR':
      const error = action.payload;
      return {
        ...state,
        isAuthenticated: error ? false : state.isAuthenticated,
        user: error ? undefined : state.user,
        error: action.payload,
      };
    default:
      return state;
  }
};

type Props = {
  children?: React.ReactNode;
};

export const AuthProvider: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(true);

  const logout = useCallback(() => {
    removeAuth();
    dispatch({ type: 'LOGOUT' });
  }, [dispatch]);

  const removeError = useCallback(() => {
    dispatch({ type: 'ERROR', payload: undefined });
  }, [dispatch]);

  // get the user data from the token
  const initializeUser = useCallback(async () => {
    try {
      dispatch({ type: 'LOADING', payload: true });
      const { data } = await getUser();
      if (data) dispatch({ type: 'GET_USER', payload: data.data });
      dispatch({ type: 'LOADING', payload: false });
    } catch (error) {
      dispatch({ type: 'LOADING', payload: false });
    }
    setLoading(false);
  }, [getUser, dispatch]);

  // send the email and the password and login then refetch the
  const login = useCallback(
    async (email: string, password: string) => {
      try {
        dispatch({ type: 'LOADING', payload: true });
        const { data } = await loginApi({ email, password });
        if (data) {
          const { user, accessToken, refreshToken } = data.data;
          storeAuthResult(accessToken, refreshToken);
          dispatch({ type: 'GET_USER', payload: user });
        }

        dispatch({ type: 'ERROR', payload: undefined });
        dispatch({ type: 'LOADING', payload: false });
        return data;
      } catch (error) {
        dispatch({ type: 'ERROR', payload: (error as { message: string }).message });
        dispatch({ type: 'LOADING', payload: false });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    initializeUser();
  }, [initializeUser]);

  if (loading) return <Loading />;

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        removeError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
