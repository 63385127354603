import { createContext, useState } from 'react';

import { merge } from 'lodash';
import { ILayoutSettings } from 'app/types/rules/styles.type';
import { LayoutSettings } from 'app/components/Layout/settings';

interface ContextData {
  settings: ILayoutSettings;
  updateSettings: (settings: ILayoutSettings) => void;
}

interface Props {
  settings?: ILayoutSettings;
  children?: React.ReactNode;
}

const SettingsContext = createContext<ContextData>({
  settings: LayoutSettings,
  updateSettings: () => {},
});

export const SettingsProvider = ({ settings, children }: Props) => {
  const [currentSettings, setCurrentSettings] = useState(settings || LayoutSettings);

  const handleUpdateSettings = (update = {}) => {
    const marged = merge({}, currentSettings, update);
    setCurrentSettings(marged);
  };

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        updateSettings: handleUpdateSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;
