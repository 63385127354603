import {
  CreateUserResponse,
  CreateUserResquest,
  GetAccessTokenRequest,
  GetAccessTokenResponse,
  LoginResponse,
  UpdateRealEstateRequest,
  UpdateUserRequest,
  UpdateVehicleRequest,
} from 'app/types/api/user.type';
import { sendRequest } from 'app/api/axios.setup';
import { RestResponse } from 'app/types/api/common/index.type';
import { LoginUserInput, RealEstate, User, Vehicle } from 'app/types/rules/user.type';

export const createUser = async (input: CreateUserResquest) => {
  return sendRequest<CreateUserResquest, RestResponse<CreateUserResponse>>('post', '/user', input);
};

export const loginApi = async (input: LoginUserInput) => {
  return sendRequest<LoginUserInput, LoginResponse>('post', '/auth/login', input);
};

export const getUser = async () => {
  return sendRequest<never, RestResponse<User>>('get', '/user/getMe');
};

export const getAccessToken = async (refreshToken: string) => {
  return sendRequest<GetAccessTokenRequest, GetAccessTokenResponse>('post', '/auth/refreshToken', {
    refreshToken,
  });
};

export const getUserById = async (id: string) => {
  return sendRequest<never, RestResponse<User>>('get', `/user/${id}`);
};

export const updateUser = async (id: string, input: UpdateUserRequest) => {
  return sendRequest<UpdateUserRequest, RestResponse<User>>('put', `/user/${id}`, input);
};

export const updateVehicle = async (id: number, input: UpdateVehicleRequest) => {
  return sendRequest<UpdateVehicleRequest, RestResponse<Vehicle>>('put', `/vehicle/${id}`, input);
};

export const updateRealEstate = async (id: number, input: UpdateRealEstateRequest) => {
  return sendRequest<UpdateVehicleRequest, RestResponse<Vehicle>>(
    'put',
    `/realEstate/${id}`,
    input
  );
};

export const getVehiclesByUserId = async (id: string) => {
  return sendRequest<never, RestResponse<Vehicle[]>>('get', `/user/vehicles/${id}`);
};

export const getRealEstatesByUserId = async (id: string) => {
  return sendRequest<never, RestResponse<RealEstate[]>>('get', `/user/realEstates/${id}`);
};
