import { Container } from 'app/components/Typography';
import { RealEstate, Vehicle } from 'app/types/rules/user.type';
import { BoxContainer, FormContainer } from '../SmallComponents';
import UpdateRealEstateForm from './UpdateRealEstateForm';

interface Props {
  realEstates: RealEstate[];
  updateRealEstate: (realEstate: RealEstate) => Promise<void>;
}

const RealEstateForm: React.FC<Props> = ({ realEstates, updateRealEstate }) => {
  return (
    <Container>
      <h1>Real Estates</h1>
      <BoxContainer>
        {realEstates.map((realEstate) => (
          <UpdateRealEstateForm realEstate={realEstate} updateRealEstate={updateRealEstate} />
        ))}
      </BoxContainer>
    </Container>
  );
};

export default RealEstateForm;
