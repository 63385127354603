import { Layouts } from '.';
import Suspense from '../Suspense';

const Layout = () => {
  const Layout = Layouts['layout1'];

  return (
    <Suspense>
      <Layout />
    </Suspense>
  );
};

export default Layout;
