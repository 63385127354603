import { Breadcrumb } from 'app/components';
import { Container } from 'app/components/Typography';
import { useNavigate } from 'react-router-dom';
import { UserInput } from 'app/types/rules/user.type';
import UserForm from 'app/components/Users/UserForm';
import { userInitialValues } from './utils/constants';
import { useState } from 'react';
import { createUser } from 'app/api/rest/users';

const CreateUser = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const handleSubmit = async (values: UserInput) => {
    setLoading(true);
    setError(undefined);

    try {
      await createUser(values);
      setLoading(false);
      alert('User created successfully');
      navigate('/users/list');
    } catch (err) {
      setError((err as { message: string }).message);
      setLoading(false);
    }
  };

  return (
    <Container>
      <div className="breadcrumb">
        <Breadcrumb
          routeSegments={[
            {
              name: 'Users',
              path: '/users/list',
            },
            {
              name: 'Create User',
              path: '/users/create',
            },
          ]}
        />
        <UserForm
          initialValues={userInitialValues}
          handleSubmit={handleSubmit}
          loading={loading}
          error={error}
        />
      </div>
    </Container>
  );
};

export default CreateUser;
