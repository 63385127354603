import { LoadingButton } from '@mui/lab';
import { TextField, useMediaQuery } from '@mui/material';
import { RealEstate, Vehicle } from 'app/types/rules/user.type';
import { screenSizes } from 'app/utils/constant';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { FormContainer } from '../SmallComponents';
import { Span } from '../Typography';
import { SxProps, Theme } from '@mui/system';
import SendIcon from '@mui/icons-material/Send';

const textFieldStyle: SxProps<Theme> = {
  margin: '10px 20px',
};

const submitButtonStyle: SxProps<Theme> = {
  width: '115px',
  margin: '10px 20px',
};

interface Props {
  realEstate: RealEstate;
  updateRealEstate: (realEstate: RealEstate) => Promise<void>;
}

const UpdateRealEstateForm: React.FC<Props> = ({ realEstate, updateRealEstate }) => {
  const isMedium = useMediaQuery(`(max-width:${screenSizes.tablet})`);
  const [loading, setLoading] = useState(false);

  return (
    <Formik
      onSubmit={async (values) => {
        setLoading(true);
        await updateRealEstate(values);
        setLoading(false);
        setTimeout(() => {
          alert('Real Estate updated successfully');
        }, 1000);
      }}
      initialValues={realEstate}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <h2
            style={{
              margin: '20px',
            }}
          >
            {realEstate.title}
          </h2>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: isMedium ? 'wrap' : 'nowrap',
              margin: '10px 0',
              flexDirection: isMedium ? 'column' : 'row',
            }}
          >
            <TextField
              label="Premium Start Date"
              sx={{ ...textFieldStyle, width: '90%' }}
              name="premiumStart"
              type="date"
              value={
                values.premiumStart
                  ? new Date(values.premiumStart).toISOString().split('T')[0]
                  : undefined
              }
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.premiumStart && Boolean(errors.premiumStart)}
              helperText={touched.premiumStart && errors.premiumStart}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />

            <TextField
              label="Premium End Date"
              sx={{ ...textFieldStyle, width: '90%' }}
              name="premiumEnd"
              type="date"
              value={
                values.premiumEnd
                  ? new Date(values.premiumEnd).toISOString().split('T')[0]
                  : undefined
              }
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.premiumEnd && Boolean(errors.premiumEnd)}
              helperText={touched.premiumEnd && errors.premiumEnd}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </div>

          <LoadingButton
            loading={loading}
            sx={submitButtonStyle}
            loadingPosition="start"
            startIcon={<SendIcon />}
            variant="contained"
            type="submit"
          >
            Submit
          </LoadingButton>
          <Span color="red" sx={{ marginLeft: '1rem' }}></Span>
        </form>
      )}
    </Formik>
  );
};

export default UpdateRealEstateForm;
