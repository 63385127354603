import useAuth from 'app/hooks/useAuth';
import { User } from 'app/types/rules/user.type';
import { flat } from 'app/utils/utils';
import { Navigate, useLocation } from 'react-router-dom';
import AllPages, { AuthRoute } from '../routes';

const userHasPermission = (pathname: string, routes: AuthRoute[], user?: User) => {
  if (!user) {
    return false;
  }
  const matched = routes.find((r) => r.path === pathname);
  console.log('matched', matched?.auth);
  console.log('user', user);
  const roleAuthenticated =
    matched && matched.auth && matched.auth.length ? matched.auth.includes(user.role) : true;

  return roleAuthenticated;
};

interface Props {
  children: React.ReactNode;
}

const AuthGuard: React.FC<Props> = ({ children }) => {
  let { isAuthenticated, user } = useAuth();
  const { pathname } = useLocation();
  const routes = flat(AllPages);
  const hasPermission = userHasPermission(pathname, routes, user);
  let authenticated = isAuthenticated && hasPermission;

  return (
    <>
      {authenticated ? (
        children
      ) : (
        <Navigate replace to="/session/signin" state={{ from: pathname }} />
      )}
    </>
  );
};

export default AuthGuard;
