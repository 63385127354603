import { UserInput } from 'app/types/rules/user.type';

export const userInitialValues: UserInput = {
  email: '',
  password: '',
  username: '',
  phone: '',
  school: '',
  website: '',
};

export const defaultPassword = 'DefaultPassword123!';
