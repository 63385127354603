import { Suspense as ReactSuspence } from 'react';
import Loading from './Loading';

interface Props {
  children?: React.ReactNode;
}

const Suspense: React.FC<Props> = ({ children }) => {
  return <ReactSuspence fallback={<Loading />}>{children}</ReactSuspence>;
};

export default Suspense;
