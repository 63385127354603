import { ILayoutSettings } from 'app/types/rules/styles.type';
import { themes } from '../Theme/initThemes';
import layout1Settings from './Layout1/Layout1Settings';

// UPDATE BELOW CODE
export const LayoutSettings: ILayoutSettings = {
  activeLayout: 'layout1', // layout1, layout2
  activeTheme: 'blue',
  perfectScrollbar: false,

  themes: themes,
  layout1Settings, // open Layout1/Layout1Settings.js

  secondarysidebar: {
    show: true,
    open: false,
    theme: 'slateDark1',
  },
  // Footer options
  footer: {
    show: true,
    fixed: false,
    theme: 'slateDark1',
  },
};
