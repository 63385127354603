import AuthGuard from 'app/auth/AuthGuard';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import NotFound from 'app/views/sessions/NotFound';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import { Navigate, RouteObject, createHashRouter } from 'react-router-dom';
import { Layout } from './components';
import { RoleEnum } from './types/enum/user.enum';
import { companyAdsRoutes } from './views/companyAds/CompanyAdRoutes';
import userRoutes from './views/users/UserRoutes';

export type AuthRoute = {
  auth?: RoleEnum[];
} & RouteObject;

const routes: AuthRoute[] = [
  {
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [...dashboardRoutes, ...userRoutes, ...companyAdsRoutes],
  },
  ...sessionRoutes,
  { path: '/', element: <Navigate to="dashboard/default" /> },
  { path: '*', element: <NotFound /> },
];

export default routes;
