import Loadable from 'app/components/Loadable';
import { AuthRoute } from 'app/routes';
import { RoleEnum } from 'app/types/enum/user.enum';
import { lazy } from 'react';

const CompanyAdsList = Loadable(lazy(() => import('./CompanyAdsList')));
const CreateCompanyAd = Loadable(lazy(() => import('./CreateCompanyAd')));
const UpdateCompanyAd = Loadable(lazy(() => import('./UpdateCompanyAd')));

export const companyAdsRoutes: AuthRoute[] = [
  { path: '/companyAds/list', element: <CompanyAdsList />, auth: [RoleEnum.ADMIN] },
  { path: '/companyAds/create', element: <CreateCompanyAd />, auth: [RoleEnum.ADMIN] },
  { path: '/companyAds/update/:id', element: <UpdateCompanyAd />, auth: [RoleEnum.ADMIN] },
];
