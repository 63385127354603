import {
  getRealEstatesByUserId,
  getUserById,
  getVehiclesByUserId,
  updateRealEstate,
  updateUser,
  updateVehicle,
} from 'app/api/rest/users';
import { Breadcrumb, Loading } from 'app/components';
import { Container } from 'app/components/Typography';
import RealEstateForm from 'app/components/Users/RealEstateForm';
import UserForm from 'app/components/Users/UserForm';
import VehicleForm from 'app/components/Users/VehicleForm';
import { RealEstate, UserInput, Vehicle } from 'app/types/rules/user.type';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { defaultPassword, userInitialValues } from './utils/constants';

const UpdateUser = () => {
  const [user, setUser] = useState<UserInput>(userInitialValues);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [realEstates, setRealEstates] = useState<RealEstate[]>([]);
  const [cvPath, setCvPath] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [error, setError] = useState<string>();
  const navigate = useNavigate();
  let { id } = useParams();

  const handleSubmit = async (values: Partial<UserInput>) => {
    if (id) {
      try {
        setLoading(true);
        const { data } = await updateUser(id, values);
        setLoading(false);
        if (data.data) navigate('/users/list');
      } catch (err) {
        setError((err as { message: string }).message);
        setLoading(false);
      }
    }
  };

  const updateVehicleTrigger = async (vehicle: Vehicle) => {
    if (vehicle.premiumEnd && vehicle.premiumStart)
      await updateVehicle(vehicle.id, {
        premiumEnd: vehicle.premiumEnd,
        premiumStart: vehicle.premiumStart,
      });
  };

  const updateRealEstateTrigger = async (realEstate: RealEstate) => {
    if (realEstate.premiumEnd && realEstate.premiumStart)
      await updateRealEstate(realEstate.id, {
        premiumEnd: realEstate.premiumEnd,
        premiumStart: realEstate.premiumStart,
      });
  };

  const getUser = useCallback(
    async (id: string) => {
      setPageLoading(true);
      const { data: userData } = await getUserById(id);
      const { data: vehiclesData } = await getVehiclesByUserId(id);
      const { data: realEstatesData } = await getRealEstatesByUserId(id);
      if (vehiclesData.data) setVehicles(vehiclesData.data);
      if (realEstatesData.data) setRealEstates(realEstatesData.data);
      if (userData?.data) {
        setUser((user) => ({
          ...user,
          ...userData?.data,
        }));
        setPageLoading(false);
      }
      setCvPath(userData?.data?.cvPath);
    },
    [getUserById, setUser]
  );

  useEffect(() => {
    if (id) getUser(id);
  }, [id, getUser]);

  if (pageLoading) return <Loading />;

  return (
    <Container>
      <div className="breadcrumb">
        <Breadcrumb
          routeSegments={[
            {
              name: 'Users',
              path: '/users/list',
            },
            {
              name: 'Update User',
              path: '/users/create',
            },
          ]}
        />
        <UserForm
          cvPath={cvPath}
          initialValues={{ ...user, password: defaultPassword }}
          handleSubmit={handleSubmit}
          loading={loading}
          error={error}
          editMode
        />
        {vehicles.length > 0 && (
          <VehicleForm vehicles={vehicles} updateVehicle={updateVehicleTrigger} />
        )}
        {realEstates.length > 0 && (
          <RealEstateForm realEstates={realEstates} updateRealEstate={updateRealEstateTrigger} />
        )}
      </div>
    </Container>
  );
};

export default UpdateUser;
